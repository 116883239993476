<template>
	<div
		class="wzitem_sub pointer"
		@click="
			$router.push({
				path: '/gw_detail',
				query: { id: item.id },
			})
		"
	>
		<div style="display: flex">
			<div class="wzitem_sub_Left_line"></div>
			<div class="wzitem_sub_Left_author hovercolor">{{
				localLanguage == 2 ? (item.title ? item.title : item.title) : item.title
			}}</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "./leftTab";
export default {
	props: ["item"],
	data() {
		return {
			allData: [],
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	methods: {},
};
</script>

<style scoped>
.wzitem_sub {
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
}
.wzitem_sub_Left_author {
	margin-right: 20px;
	width: 240px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	color: #000000;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.wzitem_sub_Left_line {
	margin-left: 20px;
	margin-right: 5px;
	margin-top: 7px;
	opacity: 1;
	width: 2px;
	height: 10px;
	border-radius: 1px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: #0056ff;
}
</style>
